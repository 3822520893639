import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle"; // Assuming Particle is a component rendering particles
import { ImPointRight } from "react-icons/im";

const Projects = () => {
  return (
    <div className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading text-center">
          Services <strong className="purple">Offered</strong>
        </h1>
        {/* <p className="text-center" style={{ color: "white", fontSize: "18px", marginBottom: "40px" }}>
          Here are the services I offer:
        </p> */}
        
        <Row className="justify-content-center" style={{ marginTop: "40px" }}>
          <Col md={6} className="service-item">
            <h3 style={{ color: "white" }}>External Penetration Testing</h3>
            <p style={{ color: "white" }}>
              Systematic evaluation of external-facing assets (websites, web
              applications, email servers, VPNs, etc.) to identify
              vulnerabilities exploitable by external attackers.
            </p>
          </Col>
          <Col md={6} className="service-item">
            <h3 style={{ color: "white" }}>Internal Penetration Testing</h3>
            <p style={{ color: "white" }}>
              Evaluation of internal network infrastructure and systems to
              simulate attacks originating from inside the organization,
              assessing security posture and internal resource vulnerabilities.
            </p>
          </Col>
          {/* Add more service items similarly */}
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "40px" }}>
          <Col md={6} className="service-item">
            <h3 style={{ color: "white" }}>Security Awareness Trainings</h3>
            <p style={{ color: "white" }}>
              Educational sessions for employees on information security
              importance, equipping them with skills to recognize and mitigate
              security threats, reducing the risk of human error.
            </p>
          </Col>
          <Col md={6} className="service-item">
            <h3 style={{ color: "white" }}>
              Consultations and Penetration Testing Trainings
            </h3>
            <p style={{ color: "white" }}>
              Knowledge transfer on penetration testing techniques, tools, and
              vulnerability identification methodologies, including personalized
              1-on-1 trainings.
            </p>
          </Col>
          {/* Add more service items similarly */}
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "40px" }}>
          <Col md={6} className="service-item">
            <h3 style={{ color: "white" }}>Website Development</h3>
            <p style={{ color: "white" }}>
              Tailored website development services focusing on creating
              responsive, user-friendly, and secure websites. From simple
              landing pages to complex web applications, I and my team deliver
              solutions that align with your business objectives.
            </p>
          </Col>
          <Col md={6} className="service-item">
            <h3 style={{ color: "white" }}>Software Architecture</h3>
            <p style={{ color: "white" }}>
              Expert consultancy in software architecture design, ensuring
              scalability, reliability, and performance of your applications. I
              design robust software solutions that meet your specific
              requirements and future growth.I also provide assistance in
              modifying and optimizing your software architecture design.
            </p>
          </Col>
          {/* Add more service items similarly */}
        </Row>
        <Row className="justify-content-center" style={{ marginTop: "60px" }}>
          <Col md={8}>
            <div className="accomplishments" style={{ textAlign: "center" }}>
              <h1 className="project-heading text-center"><strong className="purple">Accomplishments</strong></h1>
              <ul style={{ color: "white", listStyle: "none", padding: 0 }}>
                <li style={{ marginBottom: "10px" }}>
                <ImPointRight /> Ranked in the Top 10 of HackerCup 2023 as part of a
                  collaborative effort with a group of friends.
                </li>
                <li style={{ marginBottom: "10px" }}>
                <ImPointRight />  Assisted in identifying and remediating multiple security
                  vulnerabilities in numerous web and mobile applications for
                  companies such as Playstation, Carvana, Bookbeat,Obiex, CHATS and more.
                </li>
                <li style={{ marginBottom: "10px" }}>
                <ImPointRight />  Ranked in the top 900 out of 4,000 participating teams in the
                  IEEE Xtreme Competition, achieving this feat individually in a
                  group competition.
                </li>
                {/* Add more accomplishments as needed */}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Projects;
