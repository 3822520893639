import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Khalifa Yakub Mohammed </span>
            from <span className="purple"> Nigeria.</span>
            <br />
            I have completed my Software Engineering BSc at Eastern
            Mediterranean University.
            <br />
            I transitioned from my Software Engineering background to be a 
            Cybersecurity Specialist.
            <br />
            <br />
            Apart from Bug Hunting and Pentesting, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Playing Games and Football
            </li>
            <li className="about-activity">
              <ImPointRight /> Coding (Side Projects/Automation)
            </li>
            <li className="about-activity">
              <ImPointRight /> Traveling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "Keep breaking until you become a fixer!"{" "}
          </p>
          <footer className="blockquote-footer">KhalifaYakub</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
